import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import RegisterTxt from '../../components/RegisterText';
import BtnComponent from '../../components/MainBtn';
import BackToLogin from '../../components/BackToLogin';
import Modal from '../../functions/renderModal';
import auth from '../auth';

const PassRecover = () => {
	const [requesting, setRequestState] = useState(false)
	const [formData, setTormData] = useState({ email: '' })
	const modalData = {
		title: 'Confira sua caixa de e-mail.',
		text: 'Enviamos um e-mail para você com as instruções de redefinição de senha',
		redirect:'login'
	}

	const handleSubmit = async e => {
		e.preventDefault()

		setRequestState(prevState => !prevState)
		const resp = await auth.requestPassRecovery(formData)
		setRequestState(prevState => !prevState)

		if (resp.error) {
			Modal({ title: 'Erro', text: resp.data.message })
		}
		else {
			Modal(modalData)
		}
	}

	const handleValue = e => {
		const value = e.target.value;
		setTormData({ email: value })
	}

	return (
		<Container>
			<Row>
				<Col>
					<h2>
						Preencha o campo abaixo com o e-mail cadastrado para alterar sua senha
					</h2>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col>
					<Form className="text-start" onSubmit={handleSubmit}>
						<FormGroup>
							<Label>
								E-mail *
							</Label>
							<Input
								type="email"
								name="email"
								onChange={handleValue}
								required
								className="input-style"
								placeholder="xxxxxxxxxxxxxxxx@xxxxxxx.xxx"
							/>
						</FormGroup>

						<Row >
							<Col className="d-flex align-items-center mt-5" md={4}>
								<BackToLogin />
							</Col>

							<Col className="mt-5" md={8}>
								<BtnComponent
									disabled={requesting}
									label="Redefinir Senha"
								/>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>

			<Row className="mt-3">
				<Col className="text-end justify-content-between">
					<RegisterTxt />
				</Col>
			</Row>
		</Container>
	)
}

export default PassRecover
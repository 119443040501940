import React from "react";
import {
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import AuthRoutes from "../pages/auth/AuthIndex";
import PainelRoutes from "../pages/painel/PainelIndex";
import PrivateRoute from "./PrivateRoute";
import auth from "../pages/auth";
import authRoutes from "./authRoutes";
import painelRoutes from "./painelRoutes";

const RoutesIndex = (props) => {

	const renderPrivateRoutes = () => {
		return painelRoutes.map((route, i) =>
			<PrivateRoute key={i} path={route.path} component={() => <PainelRoutes />} />
		)
	}

	const renderAuthRoutes = () => {
		return authRoutes.map((route, i) => {
			if (['/', '/login'].includes(route.path))
				return <Route key={i} exact path={route.path} component={() => auth.isAuthenticated() ? <Redirect to={{ pathname: '/home', state: { from: props.location } }} /> : <AuthRoutes />} />
			return <Route key={i} path={route.path} component={() => <AuthRoutes />} />
		})
	}


	return (
		<Switch>
			{renderAuthRoutes()}
			{renderPrivateRoutes()}
			<Route path="*" component={() => <Redirect to={{ pathname: '/', state: { from: props.location } }} />} />
		</Switch>
	)
}
export default RoutesIndex
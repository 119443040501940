import React from 'react'
import EpsonLogo from '../assets/svg/EpsonLogo'
import EmailContact from './EmailContact'

const AuthFooter = props => {

	return (
		<div className="auth-footer text-white d-flex align-items-center pb-3">
			<div className="w-600 flex-wrap d-flex justify-content-between w-100 m-auto container-pad">
				<EpsonLogo propClass='mt-3' />
				<EmailContact propClass='mt-3' />
			</div>
		</div>
	)
}

export default AuthFooter
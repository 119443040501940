import React
	from 'react'
import { Button } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

const BtnComponent = props => {
	const {
		label,
		disabled,
		blackColor,
		unClickable
	} = props;

	return (
		<Button disabled={disabled || unClickable} type={"submit"} className={`w-100 main-btn text-uppercase ${blackColor ? 'text-black' : ''}`}>
			{disabled ?
				<CircularProgress />
				:
				label
			}
		</Button>
	)
}

export default BtnComponent
import React from 'react'

const EmailContact = ({ propClass }) => {
	return (
		<div className={`text-start email-contact word-break ${propClass || ''}`}>
			<span>
				Dúvidas? Escreva para:
			</span>
			<br />
			<a href="mailto:atendimento@missaotintasoriginais.com.br">
				atendimento@missaotintasoriginais.com.br
			</a>
		</div>
	)
}

export default EmailContact
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import BackToLogin from '../../components/BackToLogin'
import BtnComponent from '../../components/MainBtn'
import OpenedEye from '../../assets/svg/OpenedEye'
import Modal from '../../functions/renderModal'
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from 'react-router-dom';
import auth from '../auth'

const Register = () => {
	const history = useHistory();
	const [passView, setPassView] = useState(false)
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		terms: 0,
		confirm_pass: '',
		distributor: null,
	})
	const [diferentPass, setDifPass] = useState(false)
	const [captchaValue, setCaptcha] = useState()
	const [requesting, setRequestState] = useState(false)
	const [validEmail, setEmailState] = useState()
	const [distributors, setDistributors] = useState([])

	const validateEmail = value => {
		let isValid = false
		for (let distributor of distributors) {
			if (value.endsWith(`@${distributor.domain}`)) {
				isValid = true
				setFormData(prevState => {
					return { ...prevState, distributor: distributor.id }
				})
				break
			}
		}
		return isValid
	}

	const handleValue = e => {
		const name = e.target.name
		let value = e.target.value

		setFormData(prevState => {
			return { ...prevState, [name]: value }
		});

		if (name === 'confirm_pass')
			setDifPass(formData.password !== value)
		else if (name === 'password')
			setDifPass(formData.confirm_pass !== value)
		else if (name === 'email')
			setEmailState(validateEmail(value))

	}

	const handleCaptcha = value => {
		setCaptcha(value)
	}

	const validateForm = () => {
		return captchaValue && !diferentPass && validEmail
	}

	const handleSubmit = async e => {
		e.preventDefault();
		if (validateForm()) {

			setRequestState(prevState => !prevState)
			const resp = await auth.checkEmail({ email: formData.email })
			setRequestState(prevState => !prevState)

			if (resp.error) {
				Modal({ title: 'Erro', text: resp.data.message })
			}
			else {
				if (!(typeof (resp) === 'string')) {
					Modal({ title: 'Erro', text: 'Usuário já cadastrado', redirect: 'login' })
				}
				else {
					sessionStorage.setItem('userRegistered', JSON.stringify(formData))
					history.push('/register-regulation');
				}
			}
		}
		return
	}

	useEffect(() => {
		async function fetchData() {
			setRequestState(prevState => !prevState)
			const resp = await auth.getDistributors()
			setRequestState(prevState => !prevState)

			if (resp.error)
				Modal({ title: 'Erro', text: resp.data.message })
			else
				setDistributors(resp)
		}
		fetchData()
	}, [])

	return (
		<Container id="register">
			<Row>
				<Col>
					<h2>Cadastre-se e participe dessa missão. <br /> É rápido!</h2>
				</Col>
			</Row>

			<Row>
				<Col>
					<Form className="text-start" onSubmit={handleSubmit}>
						<FormGroup>
							<Label>
								Nome completo *
							</Label>
							<Input
								placeholder="xxxxxxxxxxxxxxxxxxx"
								className="input-style"
								required
								name="name"
								onChange={handleValue}
							/>
						</FormGroup>

						<FormGroup>
							<Label>
								E-mail *
							</Label>
							<Input
								placeholder="xxxxxxxxxxxxxxx@xxxxxxxxxxx.xxx"
								className="input-style"
								type="email"
								required
								valid={validEmail === true}
								invalid={validEmail === false}
								name="email"
								onChange={handleValue}
							/>
							<FormFeedback>Por favor insira um e-mail de domnínio válido</FormFeedback>
						</FormGroup>

						<FormGroup>
							<Label>
								Senha  *
							</Label>
							<div className="d-flex input-style">
								<Input
									type={passView ? 'text' : 'password'}
									required
									name="password"
									onChange={handleValue}
									className="px-0 border-0"
									minLength="6"
									placeholder="xxxxxxxxxxxxxxxxxx"
								/>
								<span style={{ filter: `invert(${passView ? 1 : 0})` }} className="btn" onClick={() => setPassView(prevState => !prevState)}>
									<OpenedEye />
								</span>
							</div>
						</FormGroup>

						<FormGroup>
							<Label>
								Confirme a senha *
							</Label>
							<div className="d-flex input-style">
								<Input
									type={passView ? 'text' : 'password'}
									required
									invalid={diferentPass}
									minLength="6"
									name="confirm_pass"
									onChange={handleValue}
									className="px-0 border-0"
									placeholder="xxxxxxxxxxxxxxxxxx"
								/>
								<span style={{ filter: `invert(${passView ? 1 : 0})` }} className="btn" onClick={() => setPassView(prevState => !prevState)}>
									<OpenedEye />
								</span>
							</div>
						</FormGroup>

						<p className="mt-4 gray-txt">* Todos os campos são obrigatórios</p>
						<Row>
							<Col>
								<ReCAPTCHA
								className="captcha"
									sitekey="6LcvUzsdAAAAAEMeJRm8ONGDvSQTzBOD5aFa8sCh"
									onChange={handleCaptcha}
								/>
							</Col>
						</Row>

						<Row >
							<Col className="d-flex align-items-center mt-5" md={4}><BackToLogin /></Col>
							<Col className="mt-5" md={8}><BtnComponent
								disabled={requesting}
								label="Próximo" /></Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default Register
import React from 'react'

const SoundOff = () => {
	return (
		<svg width="121" height="42" viewBox="0 0 121 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M101.544 36.6532H6.92164C3.10407 36.6532 0 33.5491 0 29.7315V11.7838C0 7.96625 3.10407 4.86217 6.92164 4.86217H101.552C105.369 4.86217 108.473 7.96625 108.473 11.7838V29.7315C108.466 33.5491 105.362 36.6532 101.544 36.6532ZM6.92164 7.07622C4.31775 7.07622 2.20669 9.18728 2.20669 11.7838V29.7315C2.20669 32.3281 4.31775 34.4465 6.92164 34.4465H101.552C104.148 34.4465 106.267 32.3354 106.267 29.7315V11.7838C106.267 9.18728 104.156 7.06886 101.552 7.06886H6.92164V7.07622Z" fill="#BF4251" />
			<path d="M8.49512 25.421V24.4427H11.0328V25.6123C11.0328 26.8333 11.5698 27.2673 12.423 27.2673C13.2763 27.2673 13.8132 26.826 13.8132 25.5608C13.8132 24.1485 13.2763 23.2438 11.5183 21.7065C9.27481 19.7278 8.54661 18.3376 8.54661 16.3883C8.54661 13.7035 9.93682 12.1441 12.5481 12.1441C15.1593 12.1441 16.4539 13.7035 16.4539 16.4398V17.146H13.9162V16.2706C13.9162 15.0496 13.4307 14.5862 12.5775 14.5862C11.7242 14.5862 11.2388 15.0496 11.2388 16.2192C11.2388 17.4623 11.7978 18.367 13.5558 19.9043C15.7993 21.883 16.5054 23.2438 16.5054 25.3696C16.5054 28.15 15.0931 29.7094 12.4598 29.7094C9.82649 29.7094 8.49512 28.15 8.49512 25.421Z" fill="#BF4251" />
			<path d="M17.8374 25.4211V16.4472C17.8374 13.7183 19.2791 12.1515 21.9124 12.1515C24.5457 12.1515 25.9874 13.7109 25.9874 16.4472V25.4211C25.9874 28.15 24.5457 29.7167 21.9124 29.7167C19.2791 29.7167 17.8374 28.15 17.8374 25.4211ZM23.3026 25.5902V16.2707C23.3026 15.0496 22.7657 14.5862 21.9124 14.5862C21.0592 14.5862 20.5222 15.0496 20.5222 16.2707V25.5902C20.5222 26.8113 21.0592 27.2747 21.9124 27.2747C22.7657 27.2747 23.3026 26.8113 23.3026 25.5902Z" fill="#BF4251" />
			<path d="M27.7891 12.3943H31.6213L33.3278 24.612H33.3793L35.0858 12.3943H38.9181V29.4667H36.3804V16.5428H36.3289L34.3797 29.4667H32.1362L30.187 16.5428H30.1355V29.4667H27.7964V12.3943H27.7891Z" fill="#BF4251" />
			<path d="M40.9111 17.5872H43.493V20.169H40.9111V17.5872ZM40.9111 26.8847H43.493V29.4665H40.9111V26.8847Z" fill="#BF4251" />
			<path d="M49.2085 25.4211V16.4472C49.2085 13.7183 50.6502 12.1515 53.2835 12.1515C55.9168 12.1515 57.3585 13.7109 57.3585 16.4472V25.4211C57.3585 28.15 55.9168 29.7167 53.2835 29.7167C50.6502 29.7167 49.2085 28.15 49.2085 25.4211ZM54.6664 25.5902V16.2707C54.6664 15.0496 54.1294 14.5862 53.2762 14.5862C52.4229 14.5862 51.8859 15.0496 51.8859 16.2707V25.5902C51.8859 26.8113 52.4229 27.2747 53.2762 27.2747C54.1294 27.2747 54.6664 26.8113 54.6664 25.5902Z" fill="#BF4251" />
			<path d="M59.1611 12.3943H66.2593V14.8363H61.8459V19.5954H65.3104V22.0375H61.8459V29.474H59.1611V12.3943Z" fill="#BF4251" />
			<path d="M67.5459 12.3943H74.6441V14.8363H70.2307V19.5954H73.6952V22.0375H70.2307V29.474H67.5459V12.3943Z" fill="#BF4251" />
			<path d="M100.242 41.5151C111.706 41.5151 121 32.2217 121 20.7576C121 9.29348 111.706 0 100.242 0C88.7779 0 79.4844 9.29348 79.4844 20.7576C79.4844 32.2217 88.7779 41.5151 100.242 41.5151Z" fill="#BF4251" />
			<path d="M101.662 9.84182C100.89 9.40048 99.9703 9.41519 99.2053 9.87124L90.6434 15.0055C90.5772 15.0422 90.511 15.0643 90.4374 15.0643H86.8773C85.4503 15.0643 84.2808 16.2265 84.2808 17.6608V23.4203C84.2808 24.8473 85.4429 26.0168 86.8773 26.0168H90.4374C90.511 26.0168 90.5845 26.0389 90.6434 26.0757C91.1583 26.392 91.835 26.2228 92.1439 25.7005C92.4529 25.1856 92.291 24.5089 91.7688 24.2C91.3642 23.9573 90.9008 23.8322 90.4301 23.8322H86.8699C86.6493 23.8322 86.4654 23.6483 86.4654 23.4277V17.6682C86.4654 17.4475 86.6493 17.2636 86.8699 17.2636H90.4301C90.9008 17.2636 91.3642 17.1386 91.7614 16.8959L100.323 11.7616C100.426 11.7028 100.515 11.7249 100.573 11.7616C100.632 11.7911 100.699 11.8573 100.699 11.9749V29.1283C100.699 29.246 100.632 29.3122 100.573 29.3416C100.515 29.3784 100.426 29.4004 100.323 29.3416L95.785 26.62C95.2627 26.3111 94.5933 26.4802 94.2844 26.9951C93.9755 27.51 94.1447 28.1867 94.6595 28.4957L99.198 31.2173C99.5878 31.4526 100.022 31.5703 100.448 31.5703C100.86 31.5703 101.272 31.46 101.647 31.2467C102.42 30.8053 102.883 30.0183 102.883 29.1283V11.9602C102.898 11.0702 102.434 10.2758 101.662 9.84182Z" fill="#071119" />
			<path d="M111.467 20.5368L114.667 16.8811C115.064 16.425 115.02 15.7336 114.564 15.3364C114.108 14.9392 113.417 14.9833 113.019 15.4394L110.011 18.8744L107.003 15.4394C106.605 14.9833 105.914 14.9392 105.458 15.3364C105.002 15.7336 104.958 16.425 105.355 16.8811L108.555 20.5368L105.355 24.1926C104.958 24.6486 105.002 25.34 105.458 25.7372C105.664 25.9211 105.921 26.0094 106.179 26.0094C106.48 26.0094 106.789 25.8843 107.003 25.6343L110.011 22.1992L113.019 25.6343C113.233 25.8843 113.542 26.0094 113.843 26.0094C114.101 26.0094 114.358 25.9211 114.564 25.7372C115.02 25.34 115.064 24.6486 114.667 24.1926L111.467 20.5368Z" fill="#071119" />
		</svg>
	)
}

export default SoundOff
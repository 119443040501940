import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label } from 'reactstrap'
import RegulationDoc from '../../components/RegulationDoc'
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from "react-router-dom";
import BtnComponent from '../../components/MainBtn';
import Modal from '../../functions/renderModal';
import { useHistory } from 'react-router-dom';
import auth from '../auth';

const Regulation = () => {
	const history = useHistory();
	const [requesting, setRequestState] = useState(false)
	const [registered, setRegistered] = useState(sessionStorage.getItem('userRegistered'))
	const modalData = {
		title: 'Confirme o e-mail de cadastro',
		text: 'Enviamos um e-mail para sua caixa de entrada',
		btnLabel: 'Fechar',
		redirect: 'login'
	}

	const handleSubmit = async e => {
		e.preventDefault()

		setRequestState(prevState => !prevState)
		const resp = await auth.register(registered)
		setRequestState(prevState => !prevState)

		if (resp.error) {
			Modal({ title: 'Erro', text: resp.data.message })
		}
		else {
			sessionStorage.removeItem('userRegistered')
			Modal(modalData)
		}
	}

	const handleTerms = e => {
		const value = e.target.checked ? 1 : 0
		let currentRegistered = registered

		if (typeof (registered) === 'string')
			currentRegistered = JSON.parse(currentRegistered)

		currentRegistered.terms = value

		setRegistered(currentRegistered)
	}

	const verifyRegister = () =>{
		if (!registered)
			history.push('/register')
	}

	useEffect(() => {
		verifyRegister()
	}, []);

	return (
		<Container id="register-regulation">
			<Row className="mb-4">
				<Col>
					<h2>Para finalizar seu cadastro,<br />leia o regulamento e dê o aceite</h2>
				</Col>
			</Row>

			<Row>
				<Col>
					<div className="h-550">
						<RegulationDoc />
					</div>
				</Col>
			</Row>

			<Row >
				<Col>
					<Form onSubmit={handleSubmit}>
						<FormGroup className="d-flex mt-3">
							<Checkbox
								id="aqui"
								color="primary"
								required
								onChange={handleTerms}
							/>
							<Label for="aqui" className="btn pt-material-check text-start gray-txt">
								Declaro que li e <span className="blue-color">aceito os termos do regulamento</span> da promoção Missão Tintas Originais
							</Label>
						</FormGroup>

						<Row >
							<Col md={4} className="d-flex align-items-center mt-4">
								<Link className="text-black" to="/register">
									Voltar
								</Link>
							</Col>
							<Col className="mt-4" md={8}>
								<BtnComponent
									disabled={requesting}
									label="Acessar o site"
								/>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default Regulation
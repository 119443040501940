import Swal from 'sweetalert2'
import { createBrowserHistory } from 'history';

const Modal = ({ title, text, btnLabel, icon, redirect }) => {

	Swal.fire({
		title: title || '',
		text: text || '',
		confirmButtonText: btnLabel || 'Fechar',
		icon: icon || '',
		showCloseButton: false,
		width: 550,
		customClass: {
			popup: 'default-modal'
		}
	})
		.then(result => {
			if (redirect) {
				createBrowserHistory().push(`/${redirect}`);
				window.location.reload();
			}
		})
}
export default Modal
import React from 'react'
import { Route } from "react-router-dom";

const RenderRoutes = ({ routes }) => {

	const renderRoutes = () => {
		const mapped = routes.map((currentRoute, index) => {
			if (!currentRoute.path)
				return (
					<Route key={index}>
						{currentRoute.component}
					</Route>
				)

			return (
				<Route key={index} path={currentRoute.path} exact={currentRoute.exact || false}>
					{currentRoute.component}
				</Route>
			)
		})

		return mapped
	}

	return (
		<React.Fragment>
			{renderRoutes()}
		</React.Fragment>
	)
}

export default RenderRoutes
import './App.css';
import RoutesIndex from './routes/Routes';
import { BrowserRouter as Router, } from "react-router-dom";

function App() {
  return (
    <Router>
      <RoutesIndex />
    </Router>
  );
}

export default App;

import axios from 'axios'
import auth from '../pages/auth'

const getHeaderConfig = () => {
	const user = auth.getUser()
	const jsonUser = JSON.parse(user)
	const headerConfig = user ? { headers: { Authorization: `Bearer ${jsonUser?.access_token || ''}` } } : {}
	return headerConfig
}

const singleApiCall = async (url, method, bodyData = null) => {
	try {
		const methodsCall = {
			get: () => axios.get(url, getHeaderConfig()),
			post: () => axios.post(url, bodyData, getHeaderConfig()),
			del: () => axios.delete(url, getHeaderConfig()),
			put: () => axios.put(url, bodyData, getHeaderConfig()),
			patch: () => axios.patch(url, bodyData, getHeaderConfig()),
		}

		let data = await methodsCall[method]()

		if (data.error) {
			return { error: true, data: data.data }
		}
		return data.data
	}
	catch (e) {
		return { error: true, data: e?.response?.data ? e.response.data : e }
	}
}

export default singleApiCall
import React from 'react'

const SoundOn = () => {
	return (
		<svg width="121" height="42" viewBox="0 0 121 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M101.544 36.6459H6.92165C3.10408 36.6459 0 33.5418 0 29.7242V11.7765C0 7.9589 3.10408 4.85483 6.92165 4.85483H101.552C105.369 4.85483 108.473 7.9589 108.473 11.7765V29.7242C108.466 33.5491 105.362 36.6459 101.544 36.6459ZM6.92165 7.06887C4.31775 7.06887 2.20669 9.18729 2.20669 11.7838V29.7316C2.20669 32.3281 4.31775 34.4465 6.92165 34.4465H101.552C104.148 34.4465 106.267 32.3355 106.267 29.7316V11.7838C106.267 9.18729 104.156 7.06887 101.552 7.06887H6.92165Z" fill="#0CC470" />
			<path d="M8.49512 25.4137V24.4354H11.0328V25.6049C11.0328 26.826 11.5698 27.26 12.423 27.26C13.2763 27.26 13.8132 26.8186 13.8132 25.5535C13.8132 24.1412 13.2763 23.2364 11.5183 21.6991C9.27481 19.7204 8.54661 18.3302 8.54661 16.381C8.54661 13.6962 9.93682 12.1368 12.5481 12.1368C15.1593 12.1368 16.4539 13.6962 16.4539 16.4325V17.1386H13.9162V16.2633C13.9162 15.0423 13.4307 14.5789 12.5775 14.5789C11.7242 14.5789 11.2388 15.0423 11.2388 16.2118C11.2388 17.4549 11.7978 18.3596 13.5558 19.897C15.7993 21.8756 16.5054 23.2364 16.5054 25.3622C16.5054 28.1426 15.0931 29.702 12.4598 29.702C9.82649 29.702 8.49512 28.15 8.49512 25.4137Z" fill="#0CC470" />
			<path d="M17.8374 25.4137V16.4398C17.8374 13.7109 19.2791 12.1441 21.9124 12.1441C24.5457 12.1441 25.9874 13.7035 25.9874 16.4398V25.4137C25.9874 28.1426 24.5457 29.7094 21.9124 29.7094C19.2791 29.7094 17.8374 28.15 17.8374 25.4137ZM23.3026 25.5902V16.2706C23.3026 15.0496 22.7657 14.5862 21.9124 14.5862C21.0592 14.5862 20.5222 15.0496 20.5222 16.2706V25.5902C20.5222 26.8112 21.0592 27.2746 21.9124 27.2746C22.7657 27.2673 23.3026 26.8039 23.3026 25.5902Z" fill="#0CC470" />
			<path d="M27.7891 12.3942H31.6213L33.3279 24.612H33.3793L35.0858 12.3942H38.9181V29.4667H36.3804V16.5355H36.3289L34.3797 29.4593H32.1362L30.187 16.5355H30.1355V29.4593H27.7964V12.3942H27.7891Z" fill="#0CC470" />
			<path d="M40.9111 17.5873H43.493V20.1691H40.9111V17.5873ZM40.9111 26.8774H43.493V29.4592H40.9111V26.8774Z" fill="#0CC470" />
			<path d="M49.209 25.4137V16.4398C49.209 13.7109 50.6507 12.1441 53.284 12.1441C55.9173 12.1441 57.359 13.7035 57.359 16.4398V25.4137C57.359 28.1426 55.9173 29.7094 53.284 29.7094C50.6507 29.7094 49.209 28.15 49.209 25.4137ZM54.6669 25.5902V16.2706C54.6669 15.0496 54.1299 14.5862 53.2766 14.5862C52.4234 14.5862 51.8864 15.0496 51.8864 16.2706V25.5902C51.8864 26.8112 52.4234 27.2746 53.2766 27.2746C54.1299 27.2673 54.6669 26.8039 54.6669 25.5902Z" fill="#0CC470" />
			<path d="M59.1607 12.3942H62.5222L65.1335 22.6112H65.1849V12.3942H67.5755V29.4667H64.8172L61.5954 17.0062H61.5439V29.4667H59.1533V12.3942H59.1607Z" fill="#0CC470" />
			<path d="M100.242 41.5152C111.706 41.5152 121 32.2217 121 20.7576C121 9.29349 111.706 0 100.242 0C88.7779 0 79.4844 9.29349 79.4844 20.7576C79.4844 32.2217 88.7779 41.5152 100.242 41.5152Z" fill="#0CC470" />
			<path d="M100.956 10.82C100.206 10.3934 99.3158 10.4081 98.5803 10.8494L90.2905 15.8218C90.2316 15.8586 90.1581 15.8807 90.0845 15.8807H86.6347C85.2519 15.8807 84.1265 17.0061 84.1265 18.389V23.9645C84.1265 25.3474 85.2519 26.4728 86.6347 26.4728H90.0845C90.1581 26.4728 90.2243 26.4949 90.2831 26.5316L98.5729 31.504C98.9481 31.7321 99.3673 31.8424 99.7866 31.8424C100.184 31.8424 100.581 31.7394 100.949 31.5335C101.699 31.1068 102.148 30.3419 102.148 29.4812V26.539C102.148 25.9505 101.677 25.4798 101.089 25.4798C100.5 25.4798 100.029 25.9579 100.029 26.539V29.4812C100.029 29.5989 99.9631 29.6578 99.9116 29.6872C99.8528 29.7166 99.7719 29.7461 99.6689 29.6872L91.3791 24.7148C90.9893 24.4794 90.5406 24.3544 90.0845 24.3544H86.6347C86.4214 24.3544 86.2449 24.1778 86.2449 23.9645V18.389C86.2449 18.1756 86.4214 17.9991 86.6347 17.9991H90.0845C90.5406 17.9991 90.9893 17.8741 91.3791 17.6387L99.6689 12.6663C99.7645 12.6074 99.8528 12.6295 99.9116 12.6663C99.9705 12.6957 100.029 12.7619 100.029 12.8722V22.3022C100.029 22.8906 100.5 23.3614 101.089 23.3614C101.677 23.3614 102.148 22.8833 102.148 22.3022V12.8722C102.148 12.0116 101.706 11.2466 100.956 10.82Z" fill="#071119" />
			<path d="M104.597 15.1818C104.17 15.5864 104.156 16.2557 104.56 16.6824C104.987 17.1311 106.384 18.7861 106.384 21.2502C106.384 21.8754 106.252 24.038 104.553 25.8181C104.148 26.2447 104.163 26.9141 104.59 27.3186C104.796 27.5172 105.06 27.6128 105.318 27.6128C105.597 27.6128 105.877 27.5025 106.083 27.2818C108.26 24.9942 108.495 22.3021 108.495 21.2502C108.495 18.0873 106.811 15.9762 106.083 15.2186C105.693 14.792 105.024 14.7773 104.597 15.1818Z" fill="#071119" />
			<path d="M110.371 12.0704C109.989 11.629 109.312 11.5849 108.878 11.9674C108.437 12.3499 108.393 13.0192 108.775 13.4606C109.65 14.4683 111.695 17.2414 111.695 21.2428C111.695 25.2443 109.65 28.0174 108.775 29.0251C108.393 29.4664 108.437 30.1358 108.878 30.5183C109.077 30.6948 109.327 30.7831 109.577 30.7831C109.871 30.7831 110.165 30.658 110.379 30.4227C111.416 29.2384 113.821 25.9651 113.821 21.2428C113.821 16.5205 111.401 13.2546 110.371 12.0704Z" fill="#071119" />
		</svg>
	)
}

export default SoundOn
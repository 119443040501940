import Home from '../pages/painel/Home';
import Regulation from '../pages/painel/Regulation';

const routes = [
	{
		path: '/home',
		component: <Home />
	},
	{
		path: '/regulation',
		component: <Regulation />
	}
]

export default routes
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import BtnComponent from '../../components/MainBtn';
import Modal from '../../functions/renderModal';
import { useHistory } from 'react-router';
import auth from '../auth';

const NewPass = () => {
	const [requesting, setRequestState] = useState(false)
	const [diferentPass, setDifPass] = useState(false)
	const [userData, setUserData] = useState({})
	const tokenIndex = window.location.href.indexOf('token=')
	const id = tokenIndex !== -1 ? window.location.href.substr((tokenIndex + 6), 60) : undefined
	const history = useHistory()
	const [passValues, setPass] = useState({ first: '', second: '' })
	let modalData = {
		title: 'Sua nova senha foi cadastrada com sucesso.',
		btnLabel: 'Fechar',
		redirect: 'login'
	}

	const handleSubmit = async e => {
		e.preventDefault()
		if (!diferentPass) {
			setRequestState(prevState => !prevState)
			const submitData = {
				email: userData.email,
				password: passValues.first,
				password_confirmation: passValues.second,
				token: userData.token
			}
			const resp = await auth.changePass(submitData)
			setRequestState(prevState => !prevState)

			if (resp.error) {
				Modal({ title: 'Erro', text: resp.data.message })
			}
			else
				Modal(modalData)
		}
	}

	const handlePasses = (e) => {
		const value = e.target.value
		const name = e.target.name
		let currentPassValues = passValues

		currentPassValues[name] = value
		setPass(currentPassValues)

		if (currentPassValues.second.length > 0 && (currentPassValues.first !== currentPassValues.second))
			setDifPass(true)
		else
			setDifPass(false)
	}

	useEffect(() => {
		async function fetchData() {
			if ([undefined, ''].includes(id))
				history.push('/login')

			setRequestState(prevState => !prevState)
			const resp = await auth.validatePassRecoveryToken(id)
			setRequestState(prevState => !prevState)

			if (resp.error)
				Modal({ title: 'Erro', text: resp.data.message, redirect: 'login' })
			else
				setUserData(resp[0])
		}
		fetchData()
	}, []);

	return (
		<Container>
			<Row>
				<Col>
					<h2>Cadastre uma nova senha</h2>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col>
					<Form className="text-start" onSubmit={handleSubmit}>
						<FormGroup>
							<Label>
								Nova senha *
							</Label>
							<Input
								name="first"
								onChange={handlePasses}
								required
								className="input-style"
								placeholder="xxxxxxxxxxxxxxxxxx"
							/>
						</FormGroup>

						<FormGroup className="mt-4">
							<Label>
								Confirme a nova senha *
							</Label>
							<Input
								name="second"
								onChange={handlePasses}
								invalid={diferentPass}
								required
								className="input-style"
								placeholder="xxxxxxxxxxxxxxxxxx"
							/>
							<FormFeedback>As senhas precisam ser iguais</FormFeedback>
						</FormGroup>
						<p className="mt-4 gray-txt">* Todos os campos são obrigatórios</p>
						<div className="mt-5">
							<BtnComponent
								disabled={requesting}
								label="Confirmar"
							/>
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

export default NewPass

import React from 'react'

const OpenedEye = () => {
	return (
		<svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M8.06667 0C4.4 0 1.26867 2.28067 0 5.5C1.26867 8.71933 4.4 11 8.06667 11C11.7333 11 14.8647 8.71933 16.1333 5.5C14.8647 2.28067 11.7333 0 8.06667 0ZM8.06669 9.16665C6.04269 9.16665 4.40002 7.52398 4.40002 5.49998C4.40002 3.47598 6.04269 1.83331 8.06669 1.83331C10.0907 1.83331 11.7334 3.47598 11.7334 5.49998C11.7334 7.52398 10.0907 9.16665 8.06669 9.16665ZM8.06664 3.29999C6.8493 3.29999 5.86664 4.28265 5.86664 5.49999C5.86664 6.71732 6.8493 7.69999 8.06664 7.69999C9.28397 7.69999 10.2666 6.71732 10.2666 5.49999C10.2666 4.28265 9.28397 3.29999 8.06664 3.29999Z" fill="#C4C4C4" />
		</svg>
	)
}

export default OpenedEye
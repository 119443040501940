import apiUrl from "../constants/apiUrl"
import singleApiCall from "../functions/apiCall"

class PainelServices {
	async sendContactMessage(data) {
		return await singleApiCall(`${apiUrl}/v1/contact`, 'post', data)
	}

	async validateToken() {
		return await singleApiCall(`${apiUrl}/auth/user`, 'get')
	}
}

export default new PainelServices()
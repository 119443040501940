import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Regulation from '../pages/auth/Regulation';
import PassRecover from '../pages/auth/PassRecover';
import NewPass from '../pages/auth/NewPass';

const routes = [
	{
		path: '/',
		component: <Login />,
		exact: true
	},
	{
		path: '/login',
		component: <Login />,
	},
	{
		path: '/ativacao-conta',
		component: <Login />
	},
	{
		path: '/new-pass',
		component: <NewPass />,
	},
	{
		path: '/recuperar-senha',
		component: <NewPass />,
	},
	{
		path: '/register',
		component: <Register />
	},
	{
		path: '/pass-recovery',
		component: <PassRecover />
	},
	{
		path: '/register-regulation',
		component: <Regulation />
	}
]

export default routes
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import cartaoHome from '../../assets/img/cartao-home1.png'
import BtnComponent from '../../components/MainBtn';
import home from '../home';
import Modal from '../../functions/renderModal';
import SoundOff from '../../assets/svg/SoundOff';
import SoundOn from '../../assets/svg/SoundOn';
import { useLocation } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'

const Home = () => {
	const location = useLocation();
	const [requesting, setRequestState] = useState(false)
	const [sentMessage, setMessageState] = useState(false)
	const [isMuted, tglVideoSound] = useState(true)
	const [contactMessage, setContactMessage] = useState({
		name: undefined,
		email: undefined,
		message: undefined
	})
	const [isMobile, setMobileFlag] = useState(window.innerWidth <= 768)

	const updateMobileflag = () => {
		setMobileFlag(window.innerWidth <= 768)
	}
	const mobileVideo = "/videos/mobile.mp4"
	const desktopVideo = "/videos/desktop.mp4"
	const notebookVideo = "/videos/notebook_desktop.mp4"

	const handleSubmit = async e => {
		setMessageState(false)
		e.preventDefault()

		setRequestState(prevState => !prevState)
		const resp = await home.sendContactMessage(contactMessage)
		setRequestState(prevState => !prevState)

		if (resp.error) {
			Modal({ title: 'Erro', text: resp.data.message })
		}
		else {
			setMessageState(true)
		}

	}

	const handleValue = e => {
		const name = e.target.name
		const value = e.target.value

		setContactMessage(prevState => {
			return { ...prevState, [name]: value }
		});

	}

	// const forceScroll = () => {
	// 	const howTo = document.querySelector('a[href="/home#how-to-participate"]')
	// 	const contact = document.querySelector('a[href="/home#contact"]')
	// 	if (location.hash.includes('how-to-participate')) {
	// 		setTimeout(() => howTo.click(), 200)
	// 	}
	// 	else if (location.hash.includes('contact')) {
	// 		setTimeout(() => contact.click(), 200)
	// 	}
	// }

	useEffect(() => {
		window.scrollTo(0, 0)
		// forceScroll()
		const video = document.querySelector('video')
		video.play()
		setTimeout(() => video.play(), 1)
	}, [])

	const getCorrectVideo = () => {
		if (isMobile)
			return mobileVideo
		else if (window.innerWidth < 1440 && window.innerWidth > 1200)
			return notebookVideo
		return desktopVideo
	}

	window.addEventListener('resize', updateMobileflag);
	return (
		<React.Fragment>
			<Element name="home"></Element>
			<Row className="ghost-top-bar" id="home"><Col></Col></Row>

			<Row className="home" >
				<Col className="p-0">
					<div className="position-relative video-container" >
						<video
							muted={isMuted}
							allow="autoplay"
							autoPlay={true}
							loop={true}
							className="w-100"
							src={getCorrectVideo()}
							type="video/mp4"
							playsinline="1"
						>
						</video>
						<span onClick={() => tglVideoSound(prevState => !prevState)} className="position-absolute video-tgl btn">
							{isMuted ?
								<SoundOff />
								:
								<SoundOn />
							}
						</span>
					</div>
				</Col>
			</Row>
			<Row className="blue-bg pt-5 pb-5 how-to home">
				<Col>
					<Container >
			<Element name="how-to-participate"></Element>
						{/* <div id="how-to-participate" className="ghost-how-to"></div> */}
						<Row className="h-450">
							<Col md={6} className="d-flex align-items-center">
								<div className="desc">
									<p className="dark-color">
										A nova campanha da Epson chegou trazendo uma missão importante para o nosso negócio: combater as tintas genéricas que só trazem surpresas desagradáveis para quem compra e um risco à reputação de quem vende. Como vencer este inimigo?
										<br />
										<span className="purple-color"><strong>Com as tintas originais Epson, é claro!</strong></span>
									</p>
									<br />

									<p className="text-88 dark-color">
										O objetivo é simples: seu distribuidor terá metas mensais de vendas para novos CNPJs. Batendo a meta do mês, os vendedores, champions e GPs ganham prêmios em vale-compras para gastar onde e como quiserem!
									</p>
									<br />

									<p className="text-87 purple-color">
										<strong>
											Consulte as metas do seu distribuidor e premiações com seu gerente de produtos.
										</strong>
									</p>
								</div>
							</Col>

							<Col md={6} className="d-flex align-items-center">
								<img src={cartaoHome} alt="cartão" />
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>

			<Row className="home">
				<Col className="home-bg">
					<Row className="text-center pt-5 pb-5 purple-light-bg">
						<Col className="text-uppercase">
							<div className="w-500 m-auto">
								<h3 className="blue-color">e tem mais</h3>
								<p className="mt-4 mb-4">Os 3 melhores distribuidores em superação da meta trimestral serão premiados com troféus personalizados!</p>
								<h4 className="blue-color">Aceite a missão e ganhe muito
									<br />
									mais com as tintas originais Epson.</h4>
							</div>
						</Col>
					</Row>

					<Container className="contact-row">
						{/* <div id="contact" className="ghost-contact "></div> */}
						<Element name="contact" className="ghost-contact "></Element>
						<Row className="pt-5 pb-5">
							<Col>
								<h1 className="active-link text-center blue-color pb-4">
									<span>
										Contato
									</span>
								</h1>
								<Form className="blue-form" onSubmit={handleSubmit}>
									<Row>
										<Col className='mt-4' md={6}>
											<FormGroup>
												<Label>Nome *</Label>
												<Input
													placeholder="xxxxxxxxxxxxxxx"
													required
													onChange={handleValue}
													name="name"
												/>
											</FormGroup>
										</Col>

										<Col className='mt-4' md={6}>
											<FormGroup>
												<Label>E-mail *</Label>
												<Input
													placeholder="xxxxxxxxxxxxxxx@xxxxxxxxxxxxxx.xxx"
													required
													onChange={handleValue}
													name="email"
													type="email"
												/>
											</FormGroup>
										</Col>
									</Row>

									<Row className="mt-4">
										<Col>
											<FormGroup>
												<Label>Escreva uma mensagem *</Label>
												<Input
													placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
													required
													onChange={handleValue}
													name="message"
													type="textarea"
												/>
											</FormGroup>
										</Col>
									</Row>

									<Row className="mt-2">
										<Col>
											<span className="blue-color">
												* Todos os campos são obrigatórios
											</span>
										</Col>
									</Row>

									<Row className="mt-4 justify-content-end">
										<Col className="text-black" md={3}>
											<BtnComponent
												disabled={requesting}
												blackColor
												label="Enviar"
											/>
										</Col>
									</Row>

									{sentMessage &&
										<h5 className="blue-color"><strong>Mensagem enviada com sucesso!</strong></h5>
									}
								</Form>
							</Col>
						</Row>
					</Container>
				</Col>
			</Row>
		</React.Fragment>
	)
}

export default Home
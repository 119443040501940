import React from 'react'

const Doc = ({ whiteColor }) => {
	return (
		<React.Fragment>
			<p className={`text-justify ${whiteColor ? 'text-white' : ''}`}>
				O presente documento regula o funcionamento da campanha de incentivo a ser realizada pela empresa <strong> EPSON DO BRASIL INDÚSTRIA E COMÉRCIO LTDA.</strong>, com sede na cidade de Barueri, Estado de São Paulo, na Avenida Tucunaré, nº 720, CEP 06460-020 inscrita no CNPJ sob nº 52.106.911/0003-63 com suporte e operação pela empresa ATELIÊ DE PROPAGANDA E PUBLICIDADE LTDA. (“ATELIÊ”), com sede em Rua Madre de Deus N. 998ª, Mooca, CEP 03119-001, São Paulo/SP, inscrita no CNPJ sob o n° 04.948.241/0001-18, contratada pela EPSON.
			</p>

			<ol className={`subs text-justify ${whiteColor ? 'text-white' : ''}`}>
				<li className="title-li"><span>Objetivo</span>

					<ul>
						<li >1.1 A presente campanha de incentivo é uma realização da empresa EPSON e tem o objetivo de reconhecer e valorizar os integrantes das equipes de vendas dos DISTRIBUIDORES EPSON previamente selecionados pela empresa promotora, engajando-os na venda de tintas originais Epson (“produtos participantes”) à novos clientes e/ou clientes inativos. </li>

						<li >1.2 Tendo em vista que um dos objetivos desta campanha é incentivar vendas de “produtos participantes” para “novos clientes” e para “clientes inativos”, fica consignado que:
							<ol type="a">
								<li>considera-se “novo cliente” aquela empresa (cliente pessoa jurídica) que nunca tenha adquirido “produtos participantes” por meio de determinado DISTRIBUIDOR;</li>

								<li>considera-se “cliente inativo” aquela empresa (cliente pessoa jurídica) que não tenha realizado compra de “produtos participantes” por meio de um determinado DISTRIBUIDOR há, pelo menos, 3 meses (assim considerados os 3 (três) meses que antecedem a realização desta campanha, ou seja, abril, maio e junho de 2021).</li>
							</ol>
						</li>

						<li>1.2.1 Para caracterizar-se como “novo cliente” ou “cliente inativo”, além de preencher as condições descritas no item 1.2 supra, é imprescindível que aquela empresa compre, no mínimo, 10 (dez) garrafas de tinta, daquelas indicadas neste regulamento como “produto participante”, por mês, de um determinado DISTRIBUIDOR. As 10 (dez) garrafas podem estar numa mesma compra ou em várias compras, sendo imprescindível que as 10 (dez) sejam compradas pelo mesmo cliente e dentro do mesmo mês. </li>

						<li>1.3 Vendas realizadas por DISTRIBUIDORES a outros clientes que não aqueles expressamente referidos no item 1.2 supra não são elegíveis para esta Campanha de Incentivo.</li>
					</ul>
				</li>

				<li className="title-li"><span>A QUEM SE DESTINA</span>
					<ul>
						<li>2.1 Esta campanha é destinada à contemplação dos integrantes das equipes de vendas dos seguintes DISTRIBUIDORES EPSON:</li>

						<li className="table-parent">
							<table>
								<thead>
									<tr>
										<th>
											<strong>
												Distribuidor
											</strong>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr><td>AGIS</td></tr>
									<tr><td>ALL NATIONS</td></tr>
									<tr><td> BRAILE </td></tr>
									<tr><td> FUJIOKA </td></tr>
									<tr><td> GAZIN </td></tr>
									<tr><td> GOLDEN </td></tr>
									<tr><td> INGRAM </td></tr>
									<tr><td> MARTINS </td></tr>
									<tr><td> MAZER </td></tr>
									<tr><td> NAGEM </td></tr>
									<tr><td> PAUTA </td></tr>
									<tr><td> PORT </td></tr>
								</tbody>
							</table>

						</li>

						<li>2.2. A participação dos integrantes das equipes de vendas dos DISTRIBUIDORES EPSON se dará de forma automática e, portanto, não requer nenhum procedimento de inscrição. É necessário, todavia, que o DISTRIBUIDOR participante alcance a meta que lhe foi atribuída pela EPSON para que sua equipe esteja apta a ser contemplada.</li>

						<li>2.3. Caberá aos DISTRIBUIDORES participantes fixar critérios próprios e internos, aplicáveis aos seus colaboradores, os quais serão rigorosamente seguidos pelas citadas empresas, para que os prêmios distribuídos na forma deste regulamento sejam, ao final, entregues a esses profissionais. Os critérios devem ser conhecidos pelos profissionais de vendas antes do início de vigência desta campanha de incentivo, possibilitando, assim, transparência e condições equânimes de participação para todos.</li>
					</ul>
				</li>

				<li className="title-li"><span>PRODUTOS PARTICIPANTES</span>

					<ul>
						<li>3.1 Para os fins desta campanha, serão relevantes os resultados de vendas dos produtos abaixo relacionados, sendo todos eles os produtos da marca EPSON e que deverão, na forma deste regulamento, ser comercializados por meio do DISTRIBUIDOR participante e em favor de empresas expressamente referidas no item 1.2 supra.</li>

						<li className="table-parent">
							<table>
								<thead>
									<tr>
										<th>
											PART NUMBER
										</th>

										<th>
											EAN
										</th>

										<th>
											DESCRIÇÃO DO PRODUTO
										</th>
									</tr>
								</thead>
								<tbody>
									<tr><td>T673320-AL</td><td>0010343888289</td><td>GARRAFA DE TINTA 673 MAGENTA 70ML</td></tr>
									<tr><td>T673420-AL</td><td>0010343888296</td><td>GARRAFA DE TINTA 673 AMARELA 70ML</td></tr>
									<tr><td>T673520-AL</td><td>0010343888302</td><td>GARRAFA DE TINTA 673 CIANO CLARO 70ML</td></tr>
									<tr><td>T673220-AL</td><td>0010343888272</td><td>GARRAFA DE TINTA 673 CIANO 70ML</td></tr>
									<tr><td>T673120-AL</td><td>0010343888265</td><td>GARRAFA DE TINTA 673 PRETA 70ML</td></tr>
									<tr><td>T673620-AL</td><td>0010343888319</td><td>GARRAFA DE TINTA 673 MAGENTA CLARO 70ML</td></tr>
									<tr><td>T664220-AL</td><td>0010343885301</td><td>GARRAFA DE TINTA 664 CIANO 70ML</td></tr>
									<tr><td>T664120-AL</td><td>0010343885295</td><td>GARRAFA DE TINTA 664 PRETA 70ML</td></tr>
									<tr><td>T664320-AL</td><td>0010343885318</td><td>GARRAFA DE TINTA 664 MAGENTA 70ML</td></tr>
									<tr><td>T664420-AL</td><td>0010343885325</td><td>GARRAFA DE TINTA 664 AMARELA 70ML</td></tr>
									<tr><td>T504320-AL</td><td>0010343938762</td><td>GARRAFA TINTA MAGENTA T504 - C13T03N32A</td></tr>
									<tr><td>T504220-AL</td><td>0010343938755</td><td>GARRAFA DE TINTA CIANO T504 - C13T03N22A</td></tr>
									<tr><td>T504420-AL</td><td>0010343938779</td><td>GARRAFA TINTA AMARELO T504 - C13T03N42A</td></tr>
									<tr><td>T504120-AL</td><td>0010343938748</td><td>GARRAFA DE TINTA PRETO T504 - C13T03N12A</td></tr>
									<tr><td>T544120-AL</td><td>0010343941977</td><td>GARRAFA DE TINTA T544 PRETA EPSON</td></tr>
									<tr><td>T544220-AL</td><td>0010343941984</td><td>GARRAFA DE TINTA T544 CIANO EPSON</td></tr>
									<tr><td>T544320-AL</td><td>0010343941991</td><td>GARRAFA DE TINTA T544 MAGENTA EPSON</td></tr>
									<tr><td>T544420-AL</td><td>0010343942004</td><td>GARRAFA DE TINTA T544 AMARELA EPSON</td></tr>
									<tr><td>T774120-AL</td><td>0010343905993</td><td>GARRAFA DE TINTA 774 PRETA 140ML</td></tr>
									<tr><td>T534120-AL</td><td>0010343943865</td><td>GARRAFA DE TINTA T534 PRETA EPSON</td></tr>
									<tr><td>T555120-AL</td><td>0010343959101</td><td>GARRAFA DE TINTA T555120 PRETA FOTO EPSON</td></tr>
									<tr><td>T555220-AL</td><td>0010343959118</td><td>GARRAFA DE TINTA T555220 CIANO EPSON</td></tr>
									<tr><td>T555320-AL</td><td>0010343959125</td><td>GARRAFA DE TINTA T555320 MAGENTA EPSON</td></tr>
									<tr><td>T555420-AL</td><td>0010343959132</td><td>GARRAFA DE TINTA T555420 AMARELO EPSON</td></tr>
									<tr><td>T555520-AL</td><td>0010343959149</td><td>GARRAFA DE TINTA T555520 CINZA EPSON</td></tr>
									<tr><td>T554120-AL</td><td>0010343959095</td><td>GARRAFA DE TINTA T554120 PRETA EPSON PIGMENTADA</td></tr>
								</tbody>
							</table>
						</li>
					</ul>
				</li>

				<li className="title-li"><span>METAS DE DESEMPENHO</span>

					<ul>
						<li>4.1. Nos termos do item 2.2 deste regulamento, a contemplação dos times de venda dos DISTRIBUIDORES participantes está condicionada, ou seja, requer, que o DISTRIBUIDOR a que está vinculado o profissional de vendas, tenha atingido a meta estabelecida pela EPSON para aquela empresa.</li>

						<li>4.1.2. A ESPON irá estabelecer as metas de cada DISTRIBUIDOR e informá-los mensalmente e com a devida antecedência, sobre quais são as metas a serem cumpridas ao longo da presente campanha de incentivo. Os integrantes dos times de venda dos DISTRIBUIDORES participantes devem manter-se informados, com seus líderes, sobre as metas estabelecidas. </li>

						<li>4.1.3. As metas são mensais e, portanto, devem ser alcanças pelos DISTRIBUIDORES dentro de determinado mês. </li>

						<li>4.1.4. A EPSON poderá criar mecanismos para que um determinado DISTRIBUIDOR que não tenha alcançado a meta em um determinado mês, possa fazer algo (atingir outras metas, por exemplo) num período seguinte para, assim o fazendo, recuperar a meta/performance anteriormente não obtida. Esses mecanismos, se criados, serão devidamente informados ao DISTRIBUIDOR com a clareza e antecedência necessárias.</li>
					</ul>
				</li>

				<li className="title-li"><span>PREMIAÇÃO</span>

					<ul>
						<li>5.1. Cumpridos os requisitos deste regulamento, a promotora direcionará ao DISTRIBUIDOR a premiação que sua equipe de vendas fizer jus. Caberá a cada DISTRIBUIDOR decidir como o prêmio será atribuído à sua equipe. Essa decisão do DISTRIBUIDOR deverá contemplar critérios claros e transparentes relacionados ao desempenho em vendas dos profissionais de sua equipe, que deverá ser devidamente comunicada a respeito dos mesmos antes do início desta campanha de incentivo.</li>

						<li>5.2. Os prêmios serão do tipo “vale-compra” emitido por meio de cartões pré-pagos, bandeira Mastercard, carregado com os créditos destinados a cada contemplado, utilizável para o pagamento de compras e/ou despesas em estabelecimentos comerciais localizados no Brasil e no exterior, que aceitem essa forma de pagamento. </li>

						<li>5.2.1. A premiação será destinada obrigatoriamente aos profissionais de vendas e, por isso, os DISTRIBUIDORES deverão informar à promotora os dados do(s) contemplado(s) para que o cartão (vale-compra) possa ser emitido de forma vinculada ao(s) seu(s) respectivo(s) CPF(s).</li>

						<li>5.3. Quando da comunicação aos DISTRIBUIDORES das metas atribuídas aos seus times de vendas pela ESPON, na forma do item 4.1.2., também lhes serão informadas, mensalmente e com a devida antecedência, qual é a premiação disponibilizada para suas respectivas equipes.</li>
					</ul>
				</li>

				<li className="title-li"><span>PRAZO DE EXECUÇÃO</span>
					<ul>
						<li>6.1. A campanha será executada observado o seguinte cronograma:
							<ol type="a">
								<li>Período de venda de “produtos participantes”: <br />
									Vendas elegíveis mês de julho: serão as realizadas de 01/07/2021 a 31/07/2021<br />
									Vendas elegíveis mês de agosto: serão as realizadas de 01/08/2021 a 31/08/2021<br />
									Vendas elegíveis mês de setembro: serão as realizadas de 01/09/2021 a 30/09/2021</li>

								<li>Divulgação de todos os contemplados: <br />
									Contemplados do mês de julho: serão divulgados até 15/08/2021<br />
									Contemplados do mês de agosto: serão divulgados até 15/09/2021<br />
									Contemplados do mês de setembro: serão divulgados até 15/10/2021</li>
							</ol>
						</li>
					</ul>
				</li>

				<li className="title-li"><span>DA MEDIÇÃO DO DESEMPENHO DOS PARTICIPANTES</span>
					<ul>
						<li>7.1. Para aferição do desempenho dos DISTRIBUIDORES participantes serão utilizados os relatórios de faturamento. </li>
					</ul>
				</li>

				<li className="title-li"><span>PRAZOS PARA ENTREGA DA PREMIAÇÃO</span>
					<ul>
						<li>8.1. A EPSON se compromete disponibilizar aos DISTRIBUIDORES os prêmios referidos neste regulamento no prazo de até 30 (trinta) dias contados de cada uma das apurações. Caberá, então, aos DISTRIBUIDORES fazerem a contemplação dos seus profissionais, no prazo de até 15 (quinze) dias, contados do efetivo recebimento do prêmio em sua unidade de negócios.</li>
					</ul>
				</li>

				<li className="title-li"><span>PRIVACIDADE E PROTEÇÃO DE DADOS PESSOAIS</span>
					<ul>
						<li>
							9.1. Ao participar desta campanha de incentivo, as pessoas físicas envolvidas, sejam elas os representantes dos DISTRIBUIDORES convidados, sejam os profissionais integrantes das equipes de vendas destes, autorizam, expressamente, que a promotora utilize, sem qualquer ônus, seus dados pessoais para as finalidades a seguir relacionadas:
							<ol type="a">
								<li>verificação da compatibilidade do perfil do participante com os critérios de participação;</li>

								<li>apuração dos contemplados desta campanha de incentivo;</li>

								<li>envio de comunicações gerais referentes a esta campanha de incentivo;</li>

								<li>outras atividades, ainda que não expressamente informadas, mas intrínsecas e estritamente necessárias à realização desta campanha de incentivo;</li>

								<li>formação de cadastro para reforço de mídia institucional, publicitária e demais divulgações acerca da promotora e de seus produtos, nos limites da Lei Geral de Proteção de Dados e do Código de Defesa do Consumidor.</li>
							</ol>
						</li>

						<li>9.2. Não obstante as finalidades acima informadas, a promotora esclarece que:
							<ol type="i">
								<li>Processa e armazena os dados de tráfego e de conexão ao site da Campanha de incentivo, principalmente a identificação (endereço IP) do dispositivo utilizado pelo participante com o objetivo de gerar estatísticas de acesso ao site, garantir a segurança da campanha de incentivo, assim como verificar a correta participação, garantindo sua conformidade em relação a este regulamento, e na prevenção ou detecção de acessos automáticos em seus sistemas, levando assim à exclusão do participante envolvido nesta atividade;</li>

								<li>Os dados existentes nos seus sistemas de informação possuem valor probatório em relação à data e hora de conexão, aos elementos de conexão e das informações resultantes do tratamento de dados relativo a esta campanha de incentivo;</li>

								<li>A promotora armazenará os dados pessoais coletados em ambiente seguro, observado o estado da técnica disponível, e somente permitirá que sejam acessados por pessoas qualificadas, que necessitem ter acesso aos dados pessoais para o cumprimento das finalidades aqui previstas, e previamente autorizadas pela promotora. A promotora assegura que todos esses indivíduos estão sujeitos a obrigações de sigilo e confidencialidade. </li>

								<li>Para as finalidades previstas acima, a promotora poderá compartilhar dados pessoais de participantes no âmbito de seu grupo econômico, bem como com terceiros que lhe prestem serviços relacionados à campanha de incentivo, nas seguintes circunstâncias: a) para empresa que lhe presta serviços de desenvolvimento e suporte do site, para o fim específico de administrá-lo e manter sua qualidade e segurança; b) para a agência de publicidade, para o fim específico de auxiliar na administração e gerenciamento da campanha de incentivo; c) para a empresa que lhe presta serviços relacionados à viabilização da mecânica da campanha de incentivo; d) para empresas envolvidas na contemplação de participantes, sejam aquelas que fornecerão os prêmios (tal qual a empresa emissora do cartão/vale-compra, sejam empresas contratadas para a entrega desses, e) para fins de processos judiciais e segurança: caso a promotora seja obrigada por lei a divulgar seus dados pessoais, ou, se em seu julgamento de boa-fé, essa ação for razoavelmente necessária para cumprir os processos legais, para responder a quaisquer reivindicações, ou para proteger a segurança ou os direitos da promotora; f) em caso de incorporação ou aquisição de toda ou parte da promotora por uma outra companhia, ou caso a promotora realize a venda de toda ou uma parte de seu negócio, a adquirente que terá acesso às informações mantidas por esse negócio da promotora, as quais poderiam incluir dados pessoais, sujeito à lei aplicável. Do mesmo modo, os dados pessoais poderão ser transferidos como parte de uma reestruturação corporativa, processo de insolvência ou outro evento similar, se permitido e feito de acordo com a lei aplicável. </li>

								<li>Quaisquer terceiros com os quais a promotora compartilhe seus dados pessoais estarão vinculados a rígidas regras de privacidade, proteção e segurança para o tratamento de seus dados pessoais. A promotora não permite que quaisquer terceiros usem os dados de qualquer outra maneira que não esteja de acordo com nossas instruções expressas e específicas, e com as leis de privacidade e proteção de dados pessoais aplicáveis. A promotora assegura que os dados pessoais dos participantes nunca serão compartilhados com nenhum outro terceiro, que não esteja aqui especificado, ou usadas para finalidades diversas daquelas para as quais foram coletadas, também expressamente especificadas neste regulamento. </li>

								<li>À PROMOTORA É EXPRESSAMENTE VEDADA DE COMERCIALIZAR OU CEDER OS DADOS PESSOAIS COLETADOS E TRATADOS EM DECORRÊNCIA DESTA CAMPANHA DE INCENTIVO. (vii) Todas as demais condições relacionadas à proteção de seus dados pessoais estão previstas na Política de Privacidade da promotora, disponível no link https://epson.com.br/politica-de-privacidade. Na eventualidade de divergências entre as disposições sobre proteção de dados acima e conteúdo da Política de Privacidade, as disposições acima deverão prevalecer para a presente campanha de incentivo.</li>
							</ol>
						</li>
					</ul>
				</li>

				<li className="title-li"><span>CONDIÇÕES GERAIS</span>
					<ul>
						<li>10.1. A premiação é pessoal e intransferível, não podendo os prêmios ser substituídos por qualquer outro ou por dinheiro em hipótese alguma, assim como também não poderá ser objeto de venda, permuta ou doação, sob pena de adoção das medidas cabíveis. O profissional de vendas que se desligar de qualquer dos DISTRIBUIDORES ao longo do período de vigência da campanha, perderá o direito ao prêmio.</li>

						<li>10.2. Caso qualquer dos participantes contemplados com qualquer dos prêmios estiver impedido, por qualquer que seja o motivo, de receber e/ou usufruir do prêmio a que faria jus nos termos deste regulamento, o prêmio será considerado perdido, não podendo ser atribuído a qualquer outra empresa ou pessoa. </li>

						<li>10.3. A presente campanha é uma realização de exclusiva iniciativa e responsabilidade da EPSON cabendo à referida empresa, de forma soberana, revisar e alterar qualquer regra estabelecida por meio deste regulamento, se assim entender que se faz necessário para o seu bom desenvolvimento e, ainda, para o atingimento dos objetivos propostos pela empresa.</li>

						<li>10.4. A EPSON se reserva o direito de alterar este regulamento, em todo ou em parte, em razão de mudanças estratégicas ou estruturais relacionadas à sua área de vendas, a qualquer tempo e ao seu exclusivo critério, colocando as novas regras e/ou condições em vigência imediatamente, mediante prévia comunicação aos participantes. Pode, ainda, a EPSON, suspender a campanha, a seu exclusivo critério, sem que seja devida qualquer indenização ou recomposição a qualquer parte.</li>

						<li>10.5. Qualquer dúvida, divergência ou situação não prevista neste regulamento será conhecida e decidida de forma soberana e irrecorrível pela EPSON.</li>

						<li>10.6. Os profissionais de vendas participantes desta campanha de incentivo, concordam e autorizam o uso de suas imagens em qualquer tipo de mídia e peças publicitárias ou promocionais para a divulgação da sua participação e/ou conquista dos prêmios, sem quaisquer ônus para as empresas ou pessoas físicas envolvidas com a presente ação de incentivo, pelo período de até um ano contados da data do recebimento do prêmio. Os DISTRIBUIDORES participantes, por sua vez, autorizam o uso de suas marcas, nomes fantasia e razão social, para os fins necessários ao desenvolvimento desta campanha de incentivos e, também, para a divulgação de seus resultados.</li>

						<li>10.7. A participação das empresas e/ou dos profissionais referidos nesta ação, implicará na aceitação total e irrestrita de todos os termos deste regulamento.</li>

						<li>10.8. A responsabilidade da EPSON com relação aos prêmios se encerra no momento da sua entrega ao seu respectivo ganhador.</li>

						<li>10.9. Dúvidas e informações sobre a ação promocional, prêmios e acompanhamento de metas poderão ser obtidos pelos participantes através do e-mail <a href="mailto:atendimento@missaotintasoriginais.com.br">atendimento@missaotintasoriginais.com.br</a></li>
					</ul>
				</li>
			</ol>
		</React.Fragment>
	)
}

export default Doc
import React from 'react'
import { Link } from "react-router-dom";

const BackToLogin = () => {
	return (
		<Link className="text-black" to="/login">
			Voltar para o login
		</Link>
	)
}

export default BackToLogin
import React from 'react'
import { Row, Col } from 'reactstrap';
import CampaignLogo from '../assets/img/logo-missao.png'
import EmailContact from './EmailContact'
import EpsonLogo from '../assets/svg/EpsonLogo'

const HomeFooter = () => {

	return (
		<Row>
			<Col className="home-footer desktop">
				<Row >
					<Col className="d-flex flex-wrap justify-content-between">
						<span className="mt-4">
							<EpsonLogo />
						</span>
						<span className="mt-4 ml-50px">
							< EmailContact />
						</span>
						<img className="mt-4" src={CampaignLogo} alt="Missão tintas originais" />
					</Col>
				</Row>

				<Row className="mt-4">
					<Col className="text-center">
						<span className="small-text">
							*As premiações serão pagas em crédito no Cartão Incenticard Mastercard. Consulte detalhes da mecânica, metas e prêmios em seu distribuidor. Imagens meramente ilustrativas.
						</span>
					</Col>
				</Row>
			</Col>

			<Col className="home-footer mobile justify-content-between text-center text-white px-2">
				<div className="mb-5">
					< EmailContact propClass='text-center' />
				</div>
				<span className="small-text ">
					*As premiações serão pagas em crédito no Cartão Incenticard Mastercard. Consulte detalhes da mecânica, metas e prêmios em seu distribuidor. Imagens meramente ilustrativas.
				</span>
				<div className="mt-5">
					<img src={CampaignLogo} alt="Missão tintas originais" />
				</div>
				<div className="mt-5">
					<EpsonLogo />
				</div>
			</Col>
		</Row>
	)
}

export default HomeFooter
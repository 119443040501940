import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap'
import { Link } from "react-router-dom";
import RegisterTxt from '../../components/RegisterText';
import OpenedEye from '../../assets/svg/OpenedEye'
import BtnComponent from '../../components/MainBtn';
import { useHistory } from 'react-router';
import Modal from '../../functions/renderModal';
import auth from '../auth';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
	const [passView, setPassView] = useState(false)
	const [requesting, setRequestState] = useState(false)
	const [captchaValue, setCaptcha] = useState()
	const [formData, setFormData] = useState({ email: '', password: '' })
	const history = useHistory()
	const tokenIndex = window.location.href.indexOf('token=')
	const id = tokenIndex !== -1 ? window.location.href.substr(tokenIndex + 6) : undefined

	const handleSubmit = async e => {
		e.preventDefault()
		if (captchaValue) {
			setRequestState(prevState => !prevState)
			const resp = await auth.login(formData);
			setRequestState(prevState => !prevState)

			if (resp.error) {
				Modal({ title: 'Erro', text: resp.data.message })
			}
			else
				history.push('/home')
		}
	}

	const handleChange = e => {
		const value = e.target.value
		const name = e.target.name
		let currentFormData = formData
		currentFormData[name] = value

		setFormData(currentFormData)
	}

	const fetchData = async () => {
		if (![undefined, ''].includes(id)) {

			setRequestState(prevState => !prevState)
			const resp = await auth.validateRegisterToken(id)
			setRequestState(prevState => !prevState)

			if (resp.error)
				Modal({ title: 'Erro', text: resp.data.message, redirect: 'login' })
			else {
				let modalData = {
					title: 'Cadastro ativado com sucesso.',
					text: 'Agora é só arrasar na venda dos produtos Epson para aumentar suas chances de ser premiado!',
				}
				Modal(modalData)
			}
		}
	}

	const handleCaptcha = value => {
		setCaptcha(value)
	}

	useEffect(() => {
		fetchData()
	}, []);

	return (
		<Container>
			<Row>
				<Col>
					<h2>Faça o login para acessar
						<br />
						o site da promoção</h2>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col>
					<Form onSubmit={handleSubmit} className="text-start">
						<FormGroup>
							<Label>
								E-mail *
							</Label>
							<Input
								type="email"
								required
								name="email"
								onChange={handleChange}
								className="input-style"
								placeholder="xxxxxxxxxxxxxxxx@xxxxxxx.xxx"
							/>
						</FormGroup>
						<FormGroup className="mt-4">
							<Label>
								Senha *
							</Label>
							<div className="d-flex input-style">
								<Input
									type={passView ? 'text' : 'password'}
									required
									name="password"
									onChange={handleChange}
									className="px-0 border-0"
									placeholder="xxxxxxxxxxxxxxxxxx"
								/>
								<span style={{ filter: `invert(${passView ? 1 : 0})` }} className="btn" onClick={() => setPassView(prevState => !prevState)}>
									<OpenedEye />
								</span>
							</div>
						</FormGroup>
						<p className="mt-4 gray-txt">* Todos os campos são obrigatórios</p>
						<ReCAPTCHA
						className="captcha"
							sitekey="6LcvUzsdAAAAAEMeJRm8ONGDvSQTzBOD5aFa8sCh"
							onChange={handleCaptcha}
						/>
						<div className="mt-5">
							<BtnComponent
								disabled={requesting}
								unClickable={!captchaValue}
								label="ENTRAR"
							/>
						</div>
					</Form>
				</Col>
			</Row>

			<Row className="mt-3">
				<Col className="d-flex justify-content-between">
					<p className="text-start">
						<span className="small-text">
							<b>
								Esqueceu a senha?
							</b>
						</span>
						<br />
						<Link to="/pass-recovery">
							Clique aqui
						</Link>
					</p>
					<RegisterTxt />
				</Col>
			</Row>
		</Container>
	)
}

export default Login
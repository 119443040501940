import React from 'react'

const ProfileIcon = () => {

	return (
		<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM2.00015 12.9998C2.00015 19.075 6.92501 23.9998 13.0001 23.9998C19.0753 23.9998 24.0002 19.075 24.0002 12.9998C24.0002 6.9247 19.0753 1.99983 13.0001 1.99983C6.92501 1.99983 2.00015 6.9247 2.00015 12.9998Z" fill="#55C9F4" />
			<path d="M5.16883 21.7297C5.61102 21.0493 6.33237 20.5905 7.53846 20.1453C7.7108 20.0817 7.89103 20.0186 8.12185 19.9403L8.71581 19.7397C10.503 19.1293 11.3173 18.6275 11.6322 17.4581C11.6323 17.4697 11.664 17.3639 11.6928 17.2309C11.8334 16.581 11.792 15.87 11.4288 15.1814C11.2473 14.8372 10.9934 14.5251 10.6672 14.2549C9.75979 13.3976 9.33324 12.5395 9.33324 11.2224C9.33324 8.95889 11.0153 7.33325 12.9999 7.33325C14.9596 7.33325 16.6666 8.99811 16.6666 11.2224C16.6666 12.5408 16.2299 13.422 15.2769 14.2831C14.9385 14.565 14.6681 14.9332 14.4921 15.3435C14.2216 15.9743 14.1962 16.6152 14.3083 17.2066C14.3324 17.3334 14.3586 17.434 14.3814 17.5047C14.7418 18.6928 15.5793 19.2002 17.4398 19.8279L17.9538 19.9999C18.152 20.0666 18.3082 20.1205 18.4574 20.1742C19.6703 20.6108 20.3774 21.0462 20.8178 21.704L22.4796 20.5913C21.7471 19.4972 20.7136 18.8607 19.1348 18.2924C18.9703 18.2332 18.8018 18.1751 18.5915 18.1043L18.0792 17.9328C16.8335 17.5125 16.3927 17.2455 16.2901 16.9077C16.2866 16.8963 16.2811 16.8752 16.2733 16.8342C16.229 16.6001 16.2391 16.3442 16.3302 16.1318C16.3839 16.0065 16.4658 15.895 16.5886 15.7923C17.9701 14.5452 18.6666 13.1396 18.6666 11.2224C18.6666 7.8822 16.0531 5.33325 12.9999 5.33325C9.92569 5.33325 7.33324 7.83876 7.33324 11.2224C7.33324 13.134 8.01157 14.4985 9.34381 15.7531C9.51523 15.8977 9.6013 16.0035 9.65985 16.1145C9.77316 16.3293 9.78767 16.5784 9.73803 16.8077C9.73021 16.8438 9.72526 16.8604 9.72864 16.8512C9.62995 17.2018 9.2212 17.4536 8.06933 17.8471L7.47923 18.0463C7.23311 18.1298 7.03744 18.1984 6.84594 18.2691C5.27242 18.8498 4.22025 19.519 3.49182 20.6399L5.16883 21.7297Z" fill="#55C9F4" />
		</svg>
	)
}

export default ProfileIcon
function currentUrl() {
	const isLocalHost = window.location.href.includes('localhost')
	const isHomolog = window.location.href.includes('homolog')
	const isIP = window.location.href.includes('192.')

	if (isHomolog || isLocalHost || isIP)
		return 'https://api.missaotintasoriginais.bckpatl.com.br/api'
	return 'https://api.missaotintasoriginais.bckpatl.com.br/api'
}

const apiUrl = currentUrl()

export default apiUrl
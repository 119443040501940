import React, { useState } from "react"
import { useHistory } from 'react-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ProfileIcon from '../assets/svg/ProfileIcon'
import auth from "../pages/auth";
import CircularProgress from '@material-ui/core/CircularProgress';

const UserDropDown = props => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuIsOpen, setMenuState] = useState(false);
	const [user] = useState(JSON.parse(auth.getUser()));
	const history = useHistory()
	const [requestng, setRequestState] = useState(false)

	const handleClose = () => {

		setMenuState(false)
		setAnchorEl(null);
	};

	const handleClick = (event) => {
		setMenuState(true)
		setAnchorEl(event.currentTarget);
	};
	
	const logout = async () => {
		setRequestState(prevState => !prevState)
		await auth.logout()
		setRequestState(prevState => !prevState)
		history.push('/login')
	}

	return (
		<div>
			<div className={`profile-menu ${menuIsOpen ? 'active' : ''}`} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
				<ProfileIcon />
			</div>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className="mt-5 text-uppercase"
			>
				{requestng ?
					<MenuItem>
						<CircularProgress />
					</MenuItem>
					:
					<MenuItem className="blue-bg aqui" onClick={logout}><b>Olá, {user?.user.name} <br />sair</b></MenuItem>
				}
			</Menu>
		</div>
	)
}

export default UserDropDown
import authServices from "../services/authServices"
class Auth {
	getUser() {
		const user = localStorage.getItem('user')
		return user
	}

	async login(userData) {
		const resp = await authServices.login(userData)
		if (!resp.error)
			localStorage.setItem('user', JSON.stringify(resp))
		return resp
	}

	async register(registerData) {

		return await authServices.register(registerData)
	}

	async logout() {
		const resp = await authServices.logout()
		localStorage.removeItem('user')
		sessionStorage.removeItem('current-active-link')
		return resp
	}

	async checkEmail(email) {
		return await authServices.checkEmail(email)
	}

	async requestPassRecovery(email) {
		return await authServices.requestPasswordRecovery(email)
	}

	async getDistributors() {
		return await authServices.getDistributors()
	}

	async validateRegisterToken(token) {
		return await authServices.validateRegisterToken(token)
	}

	async validatePassRecoveryToken(token) {
		return await authServices.validatePassRecoveryToken(token)
	}

	async changePass(data) {
		return await authServices.changePassword(data)
	}

	isAuthenticated() {
		if (!this.getUser() || this.getUser() === null)
			return false

		return JSON.parse(this.getUser())
	}
}

export default new Auth()
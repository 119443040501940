import React from 'react'

const EpsonLogo = ({ propClass }) => {
	return (
		<a className={propClass || ''} href="https://epson.com.br/">
			<svg width="113" height="39" viewBox="0 0 113 39" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M77.8142 0C70.2 0 65.8357 5.66429 65.8357 13.5571C65.8357 21.3571 70.2928 27.1143 77.8142 27.1143C85.4285 27.1143 89.7928 21.45 89.7928 13.5571C89.7928 5.66429 85.4285 0 77.8142 0ZM77.8142 22.4714C72.9857 22.4714 71.1285 18.0143 71.1285 13.4643C71.1285 8.91429 72.9857 4.45714 77.8142 4.45714C82.6428 4.45714 84.5 8.91429 84.5 13.4643C84.5 18.0143 82.6428 22.4714 77.8142 22.4714Z" fill="white" />
				<path d="M0 26.2786H19.1286V21.7286H5.29286V15.4143H18.2V10.9572H5.29286V5.29292H19.1286V0.74292H0V26.2786Z" fill="white" />
				<path d="M31.4786 0.74292H21.3571V26.2786H26.65V18.5715H31.4786C37.9786 18.5715 42.3428 15.4143 42.3428 9.6572C42.3428 3.99292 37.9786 0.74292 31.4786 0.74292ZM31.4786 14.1143H26.65V5.29292H31.4786C34.8214 5.29292 37.05 6.77863 37.05 9.6572C37.05 12.6286 34.8214 14.1143 31.4786 14.1143Z" fill="white" />
				<path d="M107.25 0.742821V10.7714C107.25 13.5571 107.343 16.3428 107.436 18.5714C106.786 17.2714 104.929 13.4643 104.093 11.9785L97.3143 0.649963H91.8357V26.1857H97.0357V15.9714C97.0357 13.1857 96.9429 10.5857 96.85 8.44996C97.5 9.74996 99.3571 13.5571 100.193 15.0428L106.971 26.3714H112.45V0.742821H107.25Z" fill="white" />
				<path d="M57.0143 11.3286C50.5143 9.65714 49.2143 9.37857 49.2143 7.33571C49.2143 5.2 51.4429 4.45714 53.3 4.45714C55.9929 4.45714 58.4072 5.2 58.5929 8.35714H63.8857C63.8857 2.32143 58.9643 0 53.5786 0C48.9357 0 43.9215 2.50714 43.9215 7.8C43.9215 12.5357 47.7286 14.0214 51.7215 15.0429C55.5286 16.0643 59.4286 16.6214 59.4286 19.4071C59.4286 22.0071 56.1786 22.6571 54.2286 22.6571C51.1643 22.6571 48.2857 21.3571 48.2857 17.9214H42.9929C42.9 24.3286 48.2857 27.1143 53.95 27.1143C61.0072 27.1143 64.7214 23.5857 64.7214 18.85C64.8143 12.9071 58.8715 11.7929 57.0143 11.3286Z" fill="white" />
				<path d="M0 31.6642H5.01429V32.6856H1.3V34.5428H4.82857V35.5642H1.3V37.607H5.2V38.6285H0V31.6642Z" fill="white" />
				<path d="M6.1286 31.6642H7.61432L9.19289 34.0785L10.8643 31.6642H12.2572L9.93574 35.007L12.4429 38.6285H10.9572L9.19289 35.9356L7.4286 38.6285H6.03574L8.54289 35.007L6.1286 31.6642Z" fill="white" />
				<path d="M18.3857 33.8929C18.2 33.1501 17.6429 32.5001 16.6214 32.5001C15.1357 32.5001 14.4857 33.8001 14.4857 35.1929C14.4857 36.5858 15.1357 37.8858 16.6214 37.8858C17.7357 37.8858 18.2929 37.0501 18.3857 36.0286H19.5929C19.5 37.7001 18.2929 38.8144 16.6214 38.8144C14.5786 38.8144 13.2786 37.1429 13.2786 35.1929C13.2786 33.2429 14.4857 31.5715 16.6214 31.5715C18.2 31.5715 19.4072 32.4072 19.5929 33.9858H18.3857V33.8929Z" fill="white" />
				<path d="M21.2643 31.6642H26.2786V32.6856H22.4714V34.5428H26V35.5642H22.4714V37.607H26.3714V38.6285H21.2643V31.6642Z" fill="white" />
				<path d="M28.0428 31.6642H33.0571V32.6856H29.25V34.5428H32.7786V35.5642H29.25V37.607H33.15V38.6285H28.0428V31.6642Z" fill="white" />
				<path d="M34.7285 31.6642H37.6071C39.7428 31.6642 40.6714 33.2428 40.6714 35.0999C40.6714 37.0499 39.7428 38.5356 37.6071 38.5356H34.7285V31.6642ZM35.9357 37.6999H37.1428C39.0928 37.6999 39.4643 36.5856 39.4643 35.1928C39.4643 33.7999 39.0928 32.6856 37.1428 32.6856H35.9357V37.6999Z" fill="white" />
				<path d="M47.45 35.9356L44.85 31.6642H46.2429L48.1 34.8213L49.9572 31.6642H51.35L48.75 35.9356V38.6285H47.5429V35.9356H47.45Z" fill="white" />
				<path d="M58.8715 35.1929C58.8715 37.1429 57.6643 38.8144 55.5286 38.8144C53.3929 38.8144 52.1858 37.1429 52.1858 35.1929C52.1858 33.2429 53.3929 31.5715 55.5286 31.5715C57.6643 31.5715 58.8715 33.1501 58.8715 35.1929ZM53.3929 35.1929C53.3929 36.4929 54.0429 37.8858 55.5286 37.8858C57.0143 37.8858 57.6643 36.5858 57.6643 35.1929C57.6643 33.8001 57.0143 32.5001 55.5286 32.5001C54.0429 32.5001 53.3929 33.8929 53.3929 35.1929Z" fill="white" />
				<path d="M60.45 31.6642H61.6572V35.7499C61.6572 36.6785 61.75 37.7928 63.3286 37.7928C64.9072 37.7928 65 36.6785 65 35.7499V31.6642H66.2072V36.1213C66.2072 37.8856 65.0929 38.8142 63.3286 38.8142C61.5643 38.8142 60.45 37.8856 60.45 36.1213V31.6642Z" fill="white" />
				<path d="M68.1572 31.6642H71.5C72.9857 31.6642 73.8215 32.407 73.8215 33.5213C73.8215 34.8213 72.8929 35.1928 72.7072 35.2856C73.1715 35.3785 73.7286 35.657 73.7286 36.7713C73.7286 37.607 73.8215 38.3499 74.1 38.6285H72.8C72.6143 38.3499 72.6143 37.9785 72.6143 37.607C72.6143 36.307 72.3357 35.7499 71.2214 35.7499H69.4572V38.6285H68.25V31.6642H68.1572ZM69.4572 34.9142H71.5C72.2429 34.9142 72.7072 34.5428 72.7072 33.7999C72.7072 32.8713 72.15 32.7785 71.5 32.7785H69.55V34.9142H69.4572Z" fill="white" />
				<path d="M78.1857 31.6642H79.4857L81.25 37.1428L83.0142 31.6642H84.2214L81.8071 38.6285H80.4142L78.1857 31.6642Z" fill="white" />
				<path d="M85.5215 31.6642H86.7286V38.6285H85.5215V31.6642Z" fill="white" />
				<path d="M89.6071 36.3072C89.6071 37.4215 90.4428 37.7929 91.3714 37.7929C92.4857 37.7929 92.8571 37.2358 92.8571 36.7715C92.8571 36.2144 92.5785 36.0286 92.2999 35.9358C91.8357 35.7501 91.1856 35.6572 90.2571 35.3786C89.0499 35.1001 88.6785 34.3572 88.6785 33.6144C88.6785 32.2215 89.9785 31.5715 91.1856 31.5715C92.6714 31.5715 93.8785 32.3144 93.8785 33.8001H92.6714C92.5785 32.8715 92.0214 32.5929 91.1856 32.5929C90.6285 32.5929 89.8857 32.7786 89.8857 33.5215C89.8857 33.9858 90.2571 34.2644 90.7214 34.4501C90.8142 34.4501 92.3928 34.9144 92.7642 35.0072C93.6928 35.2858 94.1571 36.0286 94.1571 36.7715C94.1571 38.3501 92.7642 39.0001 91.3714 39.0001C89.7928 39.0001 88.4928 38.2572 88.4928 36.4929H89.6071V36.3072Z" fill="white" />
				<path d="M95.6428 31.6642H96.85V38.6285H95.6428V31.6642Z" fill="white" />
				<path d="M105.3 35.1929C105.3 37.1429 104.093 38.8144 101.957 38.8144C99.8214 38.8144 98.6143 37.1429 98.6143 35.1929C98.6143 33.2429 99.8214 31.5715 101.957 31.5715C104.093 31.5715 105.3 33.1501 105.3 35.1929ZM99.8214 35.1929C99.8214 36.4929 100.471 37.8858 101.957 37.8858C103.443 37.8858 104.093 36.5858 104.093 35.1929C104.093 33.8001 103.443 32.5001 101.957 32.5001C100.471 32.5001 99.8214 33.8929 99.8214 35.1929Z" fill="white" />
				<path d="M106.879 31.6642H108.179L111.336 36.7713V31.6642H112.45V38.6285H111.15L107.993 33.5213V38.6285H106.786V31.6642H106.879Z" fill="white" />
			</svg>
		</a>
	)
}

export default EpsonLogo
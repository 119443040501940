import React from 'react'
import { Link } from "react-router-dom";

const RegisterTxt = () => {
	return (
		<p className="text-end">
			<span>
				<b>
					Ainda não é cadastrado?
				</b>
			</span>
			<br />
			<Link to="/register">
				Faça o seu cadastro
			</Link>
		</p>
	)
}

export default RegisterTxt
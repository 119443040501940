import React from 'react'
import { Container, Row, Col, Card } from 'reactstrap';
import campaingLogoGif from '../../assets/img/logo-missao-animado.gif'
import AuthFooter from '../../components/AuthFooter';
import RenderRoutes from '../../components/RenderRoutes';
import routes from '../../routes/authRoutes';

const Index = () => {

	return (
		<div className="auth-total">
			<Container className="text-center campaign-width container-width">
				<Row>
					<Col>
						<img className="w-100 w-360" src={campaingLogoGif} alt="Missão tintas originais" />
					</Col>
				</Row>

				<Row className="mt-5">
					<Col>
						<Card>
							<RenderRoutes routes={routes} />
						</Card>
					</Col>
				</Row>

				<Row className="mt-4">
					<Col>
						<AuthFooter />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Index
import painelServices from "../services/painelServices"
import { createBrowserHistory } from 'history';

class Home {
	async sendContactMessage(data) {
		return await painelServices.sendContactMessage(data)
	}

	async validateActiveToken() {
		const isValid = await painelServices.validateToken()
		
		if (isValid.error) {
			localStorage.removeItem('user')
			createBrowserHistory().push(`/login`);
			window.location.reload();
		}

		return isValid
	}
}

export default new Home()
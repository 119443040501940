import React, { useState, useEffect } from "react"
import { Row, Col } from 'reactstrap';
import EpsonLogo from '../assets/svg/EpsonLogo'
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import UserDropDown from "./UserDropDown";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hamburguer from '../assets/svg/HamburguerIcon'
import auth from "../pages/auth";
import { useHistory } from 'react-router';
import { Spinner } from 'reactstrap';
import { Element, scroller } from 'react-scroll'

const TopBar = props => {
	const [menuIsOpen, setMenuState] = useState(false)
	const [requesting, setRequestState] = useState(false)
	const [user] = useState(JSON.parse(auth.getUser()));
	const [activeLink, setActiveLink] = useState(sessionStorage.getItem('current-active-link'))
	const history = useHistory()

	const toggleDrawer = state => {
		setMenuState(state);
	};

	const logout = async () => {
		setRequestState(prevState => !prevState)
		await auth.logout()
		setRequestState(prevState => !prevState)
		history.push('/login')
	}

	const handleScroll = (section, activeId) => {

		setTimeout(() => {
			const all = document.querySelectorAll('a')
			all.forEach(anchor => anchor.classList.remove('active-link'))
			const active = document.getElementById(activeId)
			if (active)
				active.classList.add('active-link')
			setActiveLink(activeId)
			sessionStorage.setItem('current-active-link', activeId)

			scroller.scrollTo(section, {
				duration: 300,
				offset: -80,
				delay: 0,
			})
		}, 300)

	}

	return (
		<Row id="top-bar">
			<Col id="reg-top-bar">
				<nav className="desktop font-bold w-500 d-flex justify-content-between align-items-center m-auto text-uppercase">
					<span>
						<EpsonLogo />
					</span>

					<Link className="no-underline" to="/home" id="d-home">
						<span className="blue-color" onClick={(e) => handleScroll('home', 'd-home')}>Home</span>
					</Link>

					<Link className="no-underline" to="/home" id="d-how">
						<span className="blue-color" onClick={(e) => handleScroll('how-to-participate', 'd-how')}>Como participar</span>
					</Link>
					<NavHashLink to="/regulation" activeClassName="active-link" className="no-underline">Regulamento</NavHashLink>

					<Link className="no-underline" to="/home" id="d-contact">
						<span className="blue-color" onClick={(e) => handleScroll('contact', 'd-contact')}>Contato</span>
					</Link>

					<UserDropDown />
				</nav>

				<AppBar className="mobile app-bar">
					
					<Toolbar className="justify-content-between">
						<IconButton className="ham-btn" onClick={() => setMenuState(state => !state)} edge="start" color="inherit" aria-label="menu">
							<Hamburguer />
						</IconButton>
						<span className="epson-logo">
							<EpsonLogo />
						</span>
					</Toolbar>

					<Drawer
						className="mobile text-uppercase font-bold"
						anchor="top"
						open={menuIsOpen}
						onClose={() => toggleDrawer(false)}
					>

						<span className="blue-bg pt-2 pb-2 mt-0" ><b>Olá, {user?.user.name}</b></span>

						<div>
							<Link onClick={(e) => { handleScroll('home', 'm-home'); toggleDrawer(false) }} className={`no-underline ${sessionStorage.getItem('current-active-link') === 'm-home' ? 'active-link' : 'nao-achou'}`} to="/home" id="m-home">
								<span className="blue-color" >Home</span>
							</Link>
						</div>

						<div>
							<Link onClick={(e) => { handleScroll('how-to-participate', "m-how"); toggleDrawer(false) }} className={`no-underline ${sessionStorage.getItem('current-active-link') === 'm-how' ? 'active-link' : 'nao-achou'}`} to="/home" id="m-how">
								<span className="blue-color" >Como participar</span>
							</Link>
						</div>

						<div>
							<NavHashLink onClick={() => toggleDrawer(false)} activeClassName="active-link" to="/regulation" className="no-underline">Regulamento</NavHashLink>
						</div>

						<div>
							<Link onClick={(e) => { handleScroll('contact', 'm-contact'); toggleDrawer(false) }} className={`no-underline ${sessionStorage.getItem('current-active-link') === 'm-contact' ? 'active-link' : 'nao-achou'}`} to="/home" id="m-contact">
								<span className="blue-color" >Contato</span>
							</Link>
						</div>

						{requesting ?
							<div>
								<Spinner color="secondary" />
							</div>
							:
							<span className="blue-color" onClick={logout}>Sair</span>
						}
					</Drawer>
				</AppBar>
			</Col>
		</Row>
	)
}

export default TopBar
import apiUrl from "../constants/apiUrl"
import singleApiCall from "../functions/apiCall"

class AuthServices {
	async login(userData) {
		return await singleApiCall(`${apiUrl}/auth/login`, 'post', userData)
	}

	async register(userData) {
		return await singleApiCall(`${apiUrl}/auth/register`, 'post', userData)
	}

	async checkEmail(email) {
		return await singleApiCall(`${apiUrl}/auth/check-mail`, 'post', email)
	}

	async validateRegisterToken(token) {
		return await singleApiCall(`${apiUrl}/auth/register/activate/${token}`, 'get')
	}

	async logout() {
		return await singleApiCall(`${apiUrl}/auth/logout`, 'get')
	}

	async requestPasswordRecovery(email) {
		return await singleApiCall(`${apiUrl}/password/create`, 'post', email)
	}

	async getDistributors() {
		return await singleApiCall(`${apiUrl}/v1/distributors`, 'get')
	}

	async validatePassRecoveryToken(token) {
		return await singleApiCall(`${apiUrl}/password/find/${token}`, 'get')
	}

	async changePassword(data){
		return await singleApiCall(`${apiUrl}/password/reset`, 'post', data)
	}
}

export default new AuthServices()
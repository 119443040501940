import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Doc from '../../components/RegulationDoc'
import campaignLogo from '../../assets/img/logo-missao.png'

const Regulation = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
		sessionStorage.removeItem('current-active-link')
	}, [])

	return (
		<Row id="#title" className="regulation-row">
			<Row className="ghost-top-bar"><Col></Col></Row>
			<Col className="regulation-col">
				<div className="pt-5 pb-5 text-center">
					<img className="reg-campagin-logo" src={campaignLogo} alt="Missão Tintas Originais" />
				</div>
				<Container>
					<Row>
						<Col >
							<div className="text-center text-white">
								<h1 className="text-center active-link blue-color pb-5">
									<span>
										Regulamento
									</span>
								</h1>
							</div>
							<Doc whiteColor />
						</Col>
					</Row>
				</Container>
			</Col>
		</Row>
	)
}

export default Regulation
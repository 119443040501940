import React, {
	useEffect,
} from 'react'
import RenderRoutes from '../../components/RenderRoutes';
import HomeFooter from '../../components/HomeFooter';
import { Container } from 'reactstrap'
import routes from '../../routes/painelRoutes';
import home from '../home';
import TopBar from '../../components/HomeTopBar';

const PainelIndex = () => {

	useEffect(() => {
		async function fetchData() {
			await home.validateActiveToken()
		}
		fetchData()
	}, [])

	return (
		<Container fluid>
			<span >
				<TopBar />
			</span>
			<RenderRoutes routes={routes} />
			<HomeFooter />
		</Container>
	)
}

export default PainelIndex
import React from 'react'

const Hamburguer = () => {
	return (
		<svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2 10.6667H28M2 2H28M2 19.3333H28" stroke="#55C9F4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}

export default Hamburguer